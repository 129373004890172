<template>
  <div>
    <MainNav /> 
  </div>
</template>

<script>
  import {mapGetters} from "vuex";
  // import { mapActions } from "vuex";
  import MainNav from '@/components/Navi.vue'; // @ is an alias to /src
//  import Quiz from '@/components/quiz/QuizHome.vue'; // @ is an alias to /src

export default {
  name: 'Home',

  components: {
    MainNav,
  },

  computed:{
    ...mapGetters([
    ]),
  },

  mounted() {
  },

}
</script>

<style scoped>

</style>
